<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle uk-table-hover"
        aria-describedby="partner-address-info-table"
      >
        <thead class="thead-table-varion">
          <tr>
            <th
              v-for="(content, index) in headerTableAddress"
              :key="index"
              class="uk-table-expand uk-width-small"
            >
              <span>{{ content }}</span>
            </th>
          </tr>
        </thead>
        <template v-if="getToggleModalAddEditDiscard.loadingTable">
          <loading-table 
            :colspan="headerTableAddress.length" 
          />
        </template>
        <template v-else-if="listAddressInfoPartner.data && listAddressInfoPartner.data.length > 0">
          <tbody>
            <tr
              v-for="(content, index) in listAddressInfoPartner.data"
              :key="index"
            >
              <td>{{ content.address_type || '-' }}</td>
              <td>{{ content.address || '-' }}</td>
              <td>{{ content.latitude || '-' }}</td>
              <td>{{ content.longitude || '-' }}</td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <empty-table
            :colspan="headerTableAddress.length"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'

export default {
  components: {
    EmptyTable,
    LoadingTable
  },
  props: {
    headerTableAddress: {
      required: true,
      type: Array
    },
    listAddressInfoPartner: {
      required: true,
      type: Object
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  }
}
</script>
