<template>
  <div>
    <table-address-info
      :images="images"
      :list-address-info-partner="listAddressInfoPartner"
      :header-table-address="headerTableAddress"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
    />
    <pagination
      :total-data="totalData"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="currentPage"
      :current-limit="currentLimit"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import TableAddressInfo from './TableAddress'
import Pagination from '@/components/globals/Pagination'
import getHeaderTitle from '@/utils/header-title'
import { PREFIX_IMAGE } from "@/utils/constant"

export default {
  components: {
    TableAddressInfo,
    Pagination
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      headerTableAddress: ['Address Type', 'Address', 'Latitude', 'Longitude'],
      totalData: 10,
      currentPage: 1,
      currentLimit: 10
    }
  },
  computed: {
    ...mapGetters({
      listAddressInfoPartner: 'partner/list_address_info_partner',
      getDataModalAddEdit: 'partner/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'partner/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  watch: {
    listAddressInfoPartner(data) {
      this.totalData = data.meta.total
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      setTimeout(() => {
        this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
      }, 2000)
    }
  },
  async mounted() {
    this.loadPage()
  },
  methods: {
    ...mapActions({
      actionGetListAddressInfo: 'partner/getListAddressInfoPartner'
    }),
    ...mapMutations({
      setToggleModalAddEditDiscard: 'partner/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    async loadPage() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetListAddressInfo({ partner_id: this.id, page: this.currentPage, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changePage(page) {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = parseInt(page)
      await this.actionGetListAddressInfo({ partner_id: this.id, page, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changeLimit(event) {
      this.currentLimit = event.target.value
      this.currentPage = 1
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetListAddressInfo({ partner_id: this.id, page: 1, limit: event.target.value})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    }
  }
  
}
</script>



