<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle"
        aria-describedby="partner-facility-table"
      >
        <header-table-facility-partner />
        <template v-if="getToggleModalAddEditDiscard.loadingTable">
          <loading-table :colspan="10" />
        </template>
        <template v-else-if="listFacilityPartner.data && listFacilityPartner.data.length > 0">
          <tbody>
            <tr
              v-for="(content, index) in listFacilityPartner.data"
              :key="index"
            >
              <td>{{ content.facility_id || '-' }}</td>
              <td>{{ content.facility_type || '-' }}</td>
              <td>{{ content.facility_name || '-' }}</td>
              <td>{{ content.area || '-' }}</td>
              <td>
                <div v-if="content.status === 1">
                  <label-status
                    status="active"
                    label="Active"
                    table="true"
                  />
                </div>
                <div v-else-if="content.status === 0">
                  <label-status
                    status="inactive"
                    label="Inactive"
                    table="true"
                  />
                </div>
              </td>
              <td>
                <a
                  v-if="content.facility_type == 'Hulling Facility'"
                  uk-tooltip="View"
                  @click.prevent="$router.push({ name: 'HullingFacilityDetail', params:{ id: content.id }})"
                >
                  <span class="action-details">Details</span>
                </a>
                <a
                  v-else-if="content.facility_type == 'Pulping Facility'"
                  uk-tooltip="View"
                  @click.prevent="$router.push({ name: 'PulpingFacilityDetail', params:{ id: content.id }})"
                >
                  <span class="action-details">Details</span>
                </a>
                <a
                  v-else
                  uk-tooltip="View"
                  @click.prevent="$router.push({ name: 'PlantationDetail', params:{ id: content.id }})"
                >
                  <span class="action-details">Details</span>
                </a>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <empty-table
            :colspan="10"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderTableFacilityPartner from './HeaderTablePartner'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import LabelStatus from '@/components/globals/LabelStatus'

export default ({
  components: {
    HeaderTableFacilityPartner,
    EmptyTable,
    LoadingTable,
    LabelStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listFacilityPartner: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  }
})
</script>
