<template>
  <div>
    <header-facility-partner
      :images="images"
      :list-facility-type="listFacilityType"
      :filter-facility-partner="filterFacilityPartner"
      :reset-facility-partner="resetFacilityPartner"
      :meta-filter="metaFilter"
    />
    <content-table-facility-partner
      :images="images"
      :list-facility-partner="listFacilityPartner"
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
    />
    <pagination
      :total-data="totalData"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="currentPage"
      :current-limit="currentLimit"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import HeaderFacilityPartner from './HeaderFacilityPartner'
import ContentTableFacilityPartner from './ContentTableFacilityPartner'
import Pagination from '@/components/globals/Pagination'

export default {
  components: {
    HeaderFacilityPartner,
    ContentTableFacilityPartner,
    Pagination
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return{
      images: PREFIX_IMAGE,
      totalData: 10,
      currentPage: 1,
      currentLimit: 10
    }
  },
  computed: {
    ...mapGetters({
      listFacilityType: 'masterData/list_master_data',
      listFacilityPartner: 'partner/list_facility_partner',
      metaFilter: 'partner/meta_filter_facility',
      getToggleModalAddEditDiscard: 'partner/get_toggle_modal_add_edit_discard'
    })
  },
  watch: {
    listFacilityPartner(data) {
      this.totalData = data.meta.total
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      setTimeout(() => {
        this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
      }, 2000)
    }
  },
  async mounted () {
    await this.actionGetListMasterData({ data_type: 'facility_types' })
    await this.actionGetFacilityPartner({ partner_id: this.id, page: 1, limit: 10 })
  },
  methods: {
    ...mapActions({
      actionGetListMasterData: 'masterData/getListMasterData',
      actionGetFacilityPartner: 'partner/getFacilityPartner'
    }),
    ...mapMutations({
      setMetaFilter: 'partner/meta_filter_facility',
      setToggleModalAddEditDiscard: 'partner/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    async filterFacilityPartner() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetFacilityPartner({...this.metaFilter, partner_id: this.id, page: 1, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async resetFacilityPartner() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = 1
      this.currentLimit = 10
      await this.actionGetFacilityPartner({ partner_id: this.id, page: this.currentPage, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changePage(page) {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = parseInt(page)
      await this.actionGetFacilityPartner({...this.metaFilter, partner_id: this.id, page, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changeLimit(event) {
      this.currentLimit = event.target.value
      this.currentPage = 1
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetFacilityPartner({...this.metaFilter, partner_id: this.id, limit: event.target.value, page: 1})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    }
  }
}
</script>

<style scoped>
.uk-margin-top {
  margin-top: 24px !important;
}
.disabled {
  cursor: no-drop;
}
</style>
