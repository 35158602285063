<template>
  <thead class="thead-table-varion">
    <tr>
      <th class="uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-margin-right-small">Facility ID</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto">
              <div>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/ic_sort_table.svg`"
                      alt=""
                      class="cursor-pointer"
                      @click="sortSwitch('vcCodeUp'), changeSort('order_by', 'vc_code', 'sort_by', 'ASC')"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>

      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-margin-right-small">Facility Type</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto">
              <div>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/ic_sort_table.svg`"
                      alt=""
                      class="cursor-pointer"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>

      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-margin-right-small">Facility Name</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto">
              <div>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/ic_sort_table.svg`"
                      alt=""
                      class="cursor-pointer"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>

      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-margin-right-small">Area</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto">
              <div>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/ic_sort_table.svg`"
                      alt=""
                      class="cursor-pointer"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>

      <th class="uk-width-small">
        <span>Status</span>
      </th>
      <th class="uk-table-expand uk-width-small">
        <span>Action</span>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data() {
    return {
      images: PREFIX_IMAGE,
      sortCode: 'updateDown'
    }
  },
  computed: {
    ...mapGetters({
      metaFilter: 'partner/meta_filter_facility'
    })
  },
  methods: {
    ...mapMutations({
      setMetaFilter: 'partner/SET_META_FILTER_FACILITY'
    }),
    changeSort(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMetaFilter({
        ...this.metaFilter,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    },
    sortSwitch(val) {
      this.sortCode = val
    }
  }
}
</script>
